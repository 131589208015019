<template>
  <div class="w-100 my-5">
    <div v-if="withdrawList">
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('payout_amount') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(payoutAmount) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('already_paid') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(alreadyPaid) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-blue">&nbsp;&nbsp;&nbsp;{{ $t('pending') }}</span>
          <span class="mt-3 font-bold font-50 color-blue">{{ getPriceFormat(pending) }}</span>
        </div>
        <div class="col-sm m-2 box-white d-flex flex-column">
          <span class="fa fa-euro-sign font-15 color-red">&nbsp;&nbsp;&nbsp;{{ $t('not_paid') }}</span>
          <span class="mt-3 font-bold font-50 color-red">{{ getPriceFormat(notPaid) }}</span>
        </div>
      </div>
      <div class="row mx-auto">
        <div class="col-sm m-2 box-white row">
          <div class="col-sm my-1 vertical-center" v-if="organizerInfo">
            <img class="circle-image-30" :src="organizerInfo.photoUrl ? organizerInfo.photoUrl : assets.profile" />
            <span class="ml-2 font-15 font-bold">{{ organizerInfo.userName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center" v-else-if="eventInfo">
            <img :width="60" :height="40" :src="eventInfo.photoUrl" />
            <span class="ml-2 font-15 font-bold">{{ eventInfo.name }}</span>
          </div>
          <div class="col-sm my-1 vertical-center">
            <span class="font-bold font-15 alpha-07">{{ $t('withdraw') }}:</span>
            <span class="mx-5 font-bold font-20 color-blue">{{ getDecimalFormat(items.length) }}</span>
            <v-btn class="ml-auto button-normal" @click="generateInvoice()" v-if="eventInfo">
              <span>{{ $t('invoice') }}</span>
            </v-btn>
          </div>
          <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
            <input class="w-75" v-model="keyword" />
            <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
              <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
            </v-btn>
          </div>
        </div>
      </div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1900"
        :pdf-quality="2"
        :manual-pagination="false"
        :html-to-pdf-options="htmlToPdfOptions"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
        v-if="eventInfo && eventOrganizerInfo && eventOrganizerInfo.organizerInfo"
      >
        <section slot="pdf-content">
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-end">
              <img :src="assets.logo" :height="30" />
            </div>
            <span class="mt-2 font-10 color-black">Eventbox / Am Strassanger 2 / 86470 Thannhausen</span>
            <span class="mt-10 font-10 color-black">{{ eventOrganizerInfo.organizerInfo.name }}</span>
            <span class="mt-2 font-10 color-black">{{ eventOrganizerInfo.organizerInfo.address }}</span>
            <span class="mt-2 font-10 color-black" v-if="eventOrganizerInfo.organizerInfo.taxCode">{{ $t('tax_code') }}: {{ eventOrganizerInfo.organizerInfo.taxCode }}</span>
            <span class="mt-2 font-10 color-black" v-if="eventOrganizerInfo.organizerInfo.ustId">UST ID: {{ eventOrganizerInfo.organizerInfo.ustId }}</span>
            <span class="mt-10 font-12 color-black font-bold">Beschreibung</span>
            <span class="mt-2 font-10 color-black">Gutschrift für Provisionsleistungen siehe Kundennummer: {{ zeroPad(eventOrganizerInfo.customerNumber) }}</span>
            <span class="mt-2 font-10 color-black">Rechnungsnummer: {{ zeroPad(eventInfo.invoiceNumber) }}</span>
            <span class="mt-5 font-10 color-black">{{ eventInfo.name }}</span>
            <span class="mt-2 font-10 color-black">Für den Zeitraum: {{ getDateStringFromTimestamp(eventInfo.startAt, 'DD.MM.yyyy') }} - {{ getDateStringFromTimestamp(eventInfo.endAt, 'DD.MM.yyyy') }}</span>
            <div class="mt-10 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('sales_amount') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(salesAmount) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('handling_fee') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(handlingFee) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('refunded') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(refunded) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('refund_fee') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(refundFee) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-3 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('payout_amount') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(payoutAmount) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('paid') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(alreadyPaid) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-1 d-flex">
              <span class="col-sm-4 font-10 color-black">&nbsp;&nbsp;{{ $t('pending') }}</span>
              <span class="col-sm-4 font-10 color-black text-right">{{ getPriceFormat(pending) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <div class="mt-2 d-flex">
              <span class="col-sm-4 font-10 color-black font-bold">{{ $t('not_paid') }}</span>
              <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(notPaid) }}</span>
              <span class="col-sm-4">&nbsp;</span>
            </div>
            <span class="mt-10 font-10 color-black">Die Abwicklungsgebühr enthält 19 % Umsatzsteuer.</span>
            <span :class="`${items.length > 0 ? 'mt-10' : 'mt-20 mb-30'} mx-auto font-10 color-black`">Die Gutschrift erfolgt in den nächsten Tagen auf das angegebene Konto.</span>
            <div class="d-flex flex-column" v-if="items.length > 0">
              <span class="mt-20 ml-5 font-12 color-black font-bold">Teilvorabauszahlung & Auszahlung</span>
              <div class="mt-5 d-flex">
                <span class="col-sm-1 font-10 color-black">No.</span>
                <span class="col-sm-4 font-10 color-black text-right">Auszahlungsbetrag</span>
                <span class="col-sm-4 font-10 color-black text-right">Buchungsdatum</span>
                <span class="col-sm-3">&nbsp;</span>
              </div>
              <div class="mt-1 d-flex" v-for="(item, index) in items" :key="(item, index)">
                <span class="col-sm-1 font-10 color-black">{{ index + 1 }}</span>
                <span class="col-sm-4 font-10 color-black font-bold text-right">{{ getPriceFormat(item.withdrawAmount) }}</span>
                <span class="col-sm-4 font-10 color-black text-right">{{ item.paidAt ? getDateStringFromTimestamp(item.paidAt) : $t('pending') }}</span>
                <span class="col-sm-3">&nbsp;</span>
              </div>
            </div>
            <div :class="`${items.length > 0 ? 'mt-20' : 'mt-40'} d-flex`">
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">EVENTBOX</span>
                <span class="mt-1 font-8 color-black">Mail: info@eventboxde.com</span>
              </div>
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">Bankverbindung:</span>
                <span class="mt-1 font-8 color-black">Sparkasse Guenzburg-Krumbach</span>
                <span class="mt-1 font-8 color-black">IBAN: DE50 7205 1840 0040 7969 55</span>
                <span class="mt-1 font-8 color-black">BIC: BYLADEM1GZK</span>
              </div>
              <div class="col-sm-4 d-flex flex-column">
                <span class="font-8 color-black font-bold">Gerichtsstand</span>
                <span class="mt-1 font-8 color-black">Memmingen</span>
                <span class="mt-1 font-8 color-black">HRB 20618</span>
                <span class="mt-1 font-8 color-black">Steuernummer: 151/125/90053</span>
                <span class="mt-1 font-8 color-black">UST ID: DE361515286</span>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <div class="row mx-auto" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_200, items.length))" :key="(item, index)">
        <div class="col-sm m-2 p-0 box-white row vertical-center">
          <div class="col-sm d-flex flex-column" v-if="isMobile">
            <div class="my-1 vertical-center">
              <img class="circle-image-30" :src="item.organizerPhotoUrl" />
              <span class="ml-2 w-200px ellipsis font-12" :title="item.organizerName">{{ item.organizerName }}</span>
            </div>
            <img height="140px" :src="item.eventPhotoUrl" />
            <span class="my-1 w-250px ellipsis font-bold font-12" :title="item.eventName">{{ item.eventName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center d-flex flex-column" v-if="!isMobile">
            <img class="circle-image-80" :src="item.organizerPhotoUrl" />
            <span class="ml-2 w-200px ellipsis font-12 center" :title="item.organizerName">{{ item.organizerName }}</span>
          </div>
          <div class="col-sm my-1 vertical-center d-flex flex-column" v-if="!isMobile">
            <img height="80px" :src="item.eventPhotoUrl" />
            <span class="my-1 w-250px ellipsis font-bold font-12 center" :title="item.eventName">{{ item.eventName }}</span>
          </div>
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
          <div class="col-sm-4 d-flex flex-column">
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.withdrawId }}</span>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('remain_balance') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getPriceFormat(item.remainBalance) }}</span>
            </div>
            <div class="mt-2 d-flex justify-content-between">
              <span class="font-12 color-red">{{ $t('withdraw_amount') }}</span>
              <span class="ml-2 font-12 font-bold color-red">{{ getPriceFormat(item.withdrawAmount) }}</span>
            </div>
            <div class="mt-5 d-flex justify-content-between">
              <span class="font-12">{{ $t('requested') }}</span>
              <span class="ml-2 font-12 font-bold">{{ getDateStringFromTimestamp(item.requestedAt) }}</span>
            </div>
            <div class="my-2 d-flex justify-content-between">
              <span class="font-12">{{ $t('status') }}</span>
              <span :class="`font-12 font-bold ${item.paidAt ? 'color-blue' : 'color-red'}`">{{ item.paidAt ? $t('paid') : $t('pending') }}</span>
            </div>
          </div>
          <div class="my-5 div-divider-h" v-if="isMobile" />
          <div class="mx-3 div-divider-v" v-else />
          <div class="col-sm d-flex flex-column">
            <div class="mt-2 d-flex justify-content-between vertical-center">
              <div class="d-flex flex-column" v-if="item.organizerInfo">
                <span class="mt-2 font-12">{{ item.organizerInfo.cardName }}</span>
                <span class="mt-2 font-12">{{ item.organizerInfo.cardNumber }}</span>
                <span class="mt-2 font-12">{{ item.organizerInfo.cardBIC }}</span>
                <span class="mt-2 font-12" v-if="item.organizerInfo.swiftCode">{{ item.organizerInfo.swiftCode }}</span>
              </div>
            </div>
            <div class="mt-2 d-flex justify-content-between" v-if="item.paidAt">
              <span class="font-12">{{ $t('paid') }}</span>
              <span class="ml-2 font-12 font-bold color-blue">{{ getDateStringFromTimestamp(item.paidAt) }}</span>
            </div>
            <div class="mt-5 d-flex flex-column" v-else>
              <v-btn class="ml-auto button-normal" small @click="setWithdrawPaidTask(item.withdrawId)">
                <span>{{ $t('payment_done') }}</span>
              </v-btn>
              <v-btn class="my-2 ml-auto button-red" small @click="deleteWithdrawInfoTask(item.withdrawId)">
                <span>{{ $t('cancel_withdraw') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_200 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
  </div>
</template>


<script>
import VueHtml2pdf from 'vue-html2pdf';

import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getPriceFormat, getDecimalFormat, getDateStringFromDate, getDateStringFromTimestamp, toFileName, zeroPad, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';
import logo from '@/assets/image/logo_for_invoice.png';

export default {
  name: 'WithdrawList',
  components: {
    VueHtml2pdf
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    organizerInfo() {
      if (this.$route.params.organizerId) {
        return this.organizerList.find(element => element.userId === this.$route.params.organizerId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList || [];
    },
    eventInfo() {
      if (this.$route.params.eventId) {
        return this.eventList.find(element => element.eventId === this.$route.params.eventId);
      } else {
        return null;
      }
    },
    eventOrganizerInfo() {
      if (this.eventInfo) {
        return this.organizerList.find(element => element.userId === this.eventInfo.userId);
      } else {
        return null;
      }
    },
    withdrawList() {
      return this.$store.state.withdrawList;
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    withdrawList() {
      this.refreshData();
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile,
        logo
      },
      htmlToPdfOptions: {
        margin: 10,
        filename: 'Invoice',
        image: {
          type: 'jpeg',
          quality: 1.0
        },
        html2canvas: {
          scale: 1,
          useCORS: true
        }
      },
      hostname: window.location.hostname,
      payoutAmount: 0,
      notPaid: 0,
      alreadyPaid: 0,
      pending: 0,
      salesAmount: 0,
      handlingFee: 0,
      refunded: 0,
      refundFee: 0,
      pageIndex: 1,
      transactionList: null,
      cacheEventList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getPriceFormat,
    getDecimalFormat,
    getDateStringFromTimestamp,
    zeroPad,
    refreshData() {
      const keyword = this.keyword.toLowerCase();
      if (this.withdrawList && this.organizerList && this.eventList) {
        var payoutAmount = 0;
        var alreadyPaid = 0;
        var pending = 0;
        const items = [];
        this.withdrawList.filter(element => this.filterWithdraw(element)).forEach(withdrawInfo => {
          const item = JSON.parse(JSON.stringify(withdrawInfo));
          const organizerInfo = this.organizerList.find(element => element.userId === withdrawInfo.organizerId);
          const eventInfo = this.getEventInfo(withdrawInfo.eventId);
          item['organizerName'] = organizerInfo ? organizerInfo.userName : '';
          item['organizerPhotoUrl'] = organizerInfo ? organizerInfo.thumbnailUrl || organizerInfo.photoUrl || this.assets.profile : this.assets.profile;
          item['organizerInfo'] = organizerInfo ? organizerInfo.organizerInfo : null;
          item['eventName'] = eventInfo ? eventInfo.name : '';
          item['eventPhotoUrl'] = eventInfo ? eventInfo.thumbnailUrl || eventInfo.photoUrl : '';
          if (!keyword || !eventInfo || !organizerInfo ||
            (eventInfo && eventInfo.name.toLowerCase().includes(keyword)) ||
            (organizerInfo && organizerInfo.userName.toLowerCase().includes(keyword))) {
            payoutAmount += withdrawInfo.withdrawAmount;
            if (withdrawInfo.paidAt) {
              alreadyPaid += withdrawInfo.withdrawAmount;
            } else {
              pending += withdrawInfo.withdrawAmount;
            }
            items.push(item);
          }
        });
        items.sort((a, b) => (a.requestedAt.seconds < b.requestedAt.seconds) ? 1 : -1);
        this.pageIndex = 1;
        this.items = items;
        this.payoutAmount = payoutAmount;
        this.alreadyPaid = alreadyPaid;
        this.pending = pending;
        this.notPaid = payoutAmount - alreadyPaid - pending;
      }
    },
    filterWithdraw(element) {
      if (this.$route.params.organizerId) {
        return element.organizerId === this.$route.params.organizerId;
      } else if (this.$route.params.eventId) {
        return element.eventId === this.$route.params.eventId;
      } else {
        return true;
      }
    },
    getEventInfo(eventId) {
      const cacheInfo = this.cacheEventList.find(element => element.eventId === eventId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const eventInfo = this.eventList ? this.eventList.find(element => element.eventId === eventId) : null;
        if (eventInfo) {
          this.cacheEventList.push(eventInfo);
        }
        return eventInfo;
      }
    },
    generateInvoice() {
      if (this.eventInfo && this.eventOrganizerInfo && this.eventOrganizerInfo.organizerInfo) {
        if (this.transactionList) {
          this.htmlToPdfOptions.filename = `${toFileName(this.eventInfo.name)}_${getDateStringFromDate()}`;
          this.$refs.html2Pdf.generatePdf()
        } else {
          this.getTransactionListTask();
        }
      }
    },
    getTransactionListTask() {
      if (!this.$route.params.eventId) {
        return;
      }
      if (this.transactionList) {
        this.generateInvoice();
        return;
      }
      const loader = showLoading(this, 1);
      const q = query(collection(firestore, 'transaction'), where('eventId', '==', this.$route.params.eventId), where('totalPrice', '>', 0));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        var salesAmount = 0;
        var handlingFee = 0;
        var refunded = 0;
        var refundFee = 0;
        const list = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          salesAmount += data.salesAmount;
          handlingFee += data.handlingFee;
          if (data.refundRequestedAt) {
            refunded += data.salesAmount;
            refundFee += data.refundFee;
          }
        });
        this.transactionList = list;
        this.salesAmount = salesAmount;
        this.handlingFee = handlingFee;
        this.refunded = refunded;
        this.refundFee = refundFee;
        this.payoutAmount = salesAmount - handlingFee - refunded - refundFee;
        this.notPaid = this.payoutAmount - this.alreadyPaid - this.pending;
        this.generateInvoice();
      }).catch(error => {
        loader.hide();
        this.$toast.error('Unexpected error occurs - ' + error.message);
      });
    },
    setWithdrawPaidTask(withdrawId) {
      if (!withdrawId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm(this.$t('confirm_withdraw_done'))) {
        return;
      }
      const params = {
        functionName: 'setWithdrawPaid',
        withdrawId: withdrawId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    deleteWithdrawInfoTask(withdrawId) {
      if (!withdrawId) {
        this.$toast.error('Unexpected error occurs.');
        return;
      }
      if (!confirm('Are you sure you want to cancel this payout request?')) {
        return;
      }
      const params = {
        functionName: 'deleteWithdrawInfo',
        withdrawId: withdrawId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>